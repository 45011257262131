.card-loaders {
  padding: 0;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr;
  margin-top: 0.75rem;
}

.card-loader {
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  border-radius: var(--radius-border);
  overflow: hidden;
  max-width: var(--screen-max-width);

  height: 120px;
}

.card-loader__wrapper {
  margin-bottom: 10px;
  position: relative;
  padding-bottom: 100%;
}

.card-loader__image {
  background: #dddddd;
  height: 100%;
  position: absolute;
  width: 100%;
}

.shimmer {
  overflow: hidden;
  position: relative;
}

.shimmer::before {
  content: "";
  position: absolute;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.4) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  height: 100%;
  width: 100%;
  z-index: 1;
  animation: shimmer 1s infinite;
}

@keyframes shimmer {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
