.detail__header {
  position: sticky;
  top: 0;
  overflow: hidden;
  padding: var(--padding-page-content);
  padding-top: 0;
  z-index: 1;
}

.detail__header::before {
  border-radius: 100%;
  position: absolute;
  right: -200px;
  left: -200px;
  top: -200px;
  content: '';
  bottom: 5px;
  z-index: -1;
  box-shadow: 0 0 5px #333;
}

.detail__header--fighting::before {
  background-color: var(--color-bg-pokemon-fighting);
}
.detail__header--flying::before {
  background-color: var(--color-bg-pokemon-flying);
}
.detail__header--poison::before {
  background-color: var(--color-bg-pokemon-poison);
}
.detail__header--ground::before {
  background-color: var(--color-bg-pokemon-ground);
}
.detail__header--rock::before {
  background-color: var(--color-bg-pokemon-rock);
}
.detail__header--bug::before {
  background-color: var(--color-bg-pokemon-bug);
}
.detail__header--ghost::before {
  background-color: var(--color-bg-pokemon-ghost);
}
.detail__header--steel::before {
  background-color: var(--color-bg-pokemon-steel);
}
.detail__header--fire::before {
  background-color: var(--color-bg-pokemon-fire);
}
.detail__header--water::before {
  background-color: var(--color-bg-pokemon-water);
}
.detail__header--grass::before {
  background-color: var(--color-bg-pokemon-grass);
}
.detail__header--electric::before {
  background-color: var(--color-bg-pokemon-electric);
}
.detail__header--psychic::before {
  background-color: var(--color-bg-pokemon-psychic);
}
.detail__header--ice::before {
  background-color: var(--color-bg-pokemon-ice);
}
.detail__header--dragon::before {
  background-color: var(--color-bg-pokemon-dragon);
}
.detail__header--dark::before {
  background-color: var(--color-bg-pokemon-dark);
}
.detail__header--fairy::before {
  background-color: var(--color-bg-pokemon-fairy);
}
.detail__header--unknown::before {
  background-color: var(--color-bg-pokemon-unknown);
}
.detail__header--normal::before {
  background-color: var(--color-bg-pokemon-normal);
}

.detail__pokemon-header-image {
  margin: 0 auto;
  max-width: 220px;
}

.detail__pokemon-name {
  text-transform: capitalize;
  line-height: 1;
  color: #fff;
  text-shadow: 1px 1px 3px #999;
}

.detail__pokemon-number {
  color: #fff;
  text-shadow: 1px 1px 3px #aaa;
}

.detail__pokeball {
  position: absolute;
  top: .5rem;
  left: 50%;
  z-index: -1;
  opacity: 0.05;
  width: 20rem;
  transform: translateX(-50%) rotate(-25deg) ;
}

.detail__content {
  padding: var(--padding-page-content);
}

.detail__section-title {
  margin: 1rem 0;
}

.detail__type-tags {
  margin-top: 0.1rem;
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0.8rem 0;
}

.detail__tab-stats > figure:not(:last-child) {
  margin-bottom: 0.7rem;
}

.detail__tab-evolution-list {
  padding: 0;
}
.detail__tab-evolution-item {
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.detail__tab-evolution-item:not(:last-child) {
  margin-bottom: 2rem;
}

.detail__tab-evolution-item > span {
  position: relative;
  color: #333;
}

.detail__tab-evolution-item > span::after {
  position: absolute;
  content: '⟶';
  bottom: -3rem;
  left: 50%;
  transform: translateX(-50%);
  font-weight: 700;
  font-size: 2rem;
}

.detail__tab-evolution-item figcaption {
  text-transform: capitalize;
  text-align: center;
  color: #333;
}
.detail__tab-evolution-item figcaption[data-currentpokemon='true'] {
  color: inherit;
  font-weight: 700;
}
